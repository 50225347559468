export const BASE_DOMAIN = 'https://b.zhheo.com'
export const CHECKOUT_URL = 'https:/baidu.com'
export const LOGIN_LIMIT_COUNT = 0
export const FREE_LIMIT_COUNT = 9999999
export const RATE_LIMIT_COUNT = LOGIN_LIMIT_COUNT + FREE_LIMIT_COUNT

export const FADE_IN_ANIMATION_SETTINGS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.2 },
}
