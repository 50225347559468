import Link from 'next/link'
import { ModeToggle } from '~/components/mode-toggle'
import { Icons } from './icons'
import { buttonVariants } from '@/components/ui/button'

export default function Footer() {
  return (
    <footer className="z-50 mt-5 mb-3 flex h-16 w-full flex-col items-center justify-between space-y-3 bg-white px-3 pt-4 text-center text-slate-400 sm:mb-0 sm:h-20 sm:flex-row sm:pt-2 lg:px-12">
      <div className="flex items-center space-x-1">
      <span>公益服务由<a href="https://github.com/zhheo" target='_blank'>张洪Heo</a>搭建</span>
      <span>基于<a href="https://github.com/JimmyLv/BibiGPT-v1" target='_blank'>BibiGPT-v1</a>项目进行简化修改</span>
      </div>
    </footer>
  )
}
