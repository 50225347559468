import { Poppins } from '@next/font/google'
import clsx from 'clsx'
import React from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/ui/tooltip'
import { BASE_DOMAIN } from '~/utils/constants'

const poppins = Poppins({ weight: '800', subsets: ['latin'] })

export default function Header() {

  const bookmarkletCode = `
    javascript:(function(){ 
      if (window.location.hostname.includes('bilibili.com') || window.location.hostname.includes('youtube.com')) {
        const destinationUrl = 'https://b.zhheo.com/redirect';
        const currentUrl = encodeURIComponent(window.location.href);
        window.open(destinationUrl + '?url=' + currentUrl, '_blank');
      } else {
        alert('🔖请进入B站或YouTube视频页面，再来点书签哦！');
      }
    })();
  `;

  return (
    <header className="supports-backdrop-blur:bg-white/60 max-w-8xl sticky top-0 z-40 mx-auto w-full flex-none border-b border-slate-900/10 bg-white/95 pt-2 backdrop-blur  transition-colors duration-500 dark:border-slate-50/[0.06] dark:border-slate-300/10 dark:bg-transparent lg:z-50 lg:mx-0 lg:border-0 lg:border-b lg:border-slate-900/10 lg:px-8">
      <div className="flex items-center justify-between px-3 sm:px-3">
        <div className="flex items-center space-x-3">
          <a href={BASE_DOMAIN}>
            <h2 className={clsx('text-lg sm:text-2xl', poppins.className)}>
              <span className="text-pink-400">HeoBiGPT</span> B站摘要生成
            </h2>
          </a>
          <div
            id="banner"
            className="z-50 mx-4 flex hidden w-auto justify-center border-b border-slate-900/10 py-4 dark:border-slate-300/10 lg:mx-0 lg:block lg:border-0 lg:px-8"
          >
          </div>
        </div>
        <div className="flex shrink-0 items-center space-x-2 sm:space-x-5">
          <Tooltip>
            <TooltipContent>那可太感谢啦！</TooltipContent>
          </Tooltip>
          <a
            href={bookmarkletCode.trim()}
            className="flex hidden items-center space-x-2 sm:block"
            draggable="true"
          >
            🔖 <span className="relin-paragraph-target pl-1 text-slate-500">(书签版)</span>
          </a>
        </div>
      </div>
    </header>
  )
}
